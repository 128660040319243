#go-ctn {
    width: 45%;
}

#go-title {
    font-weight: 700;
    font-size: large;
}

#go-title span {
    margin-left: 10px;
}

#go-options-ul {
    display: flex;
    flex-direction: column;
    list-style: none;
}

#go-options-ul li {
    margin: 5px 0;
}

#go-options-ul li i {
    margin-right: 8px;
}
