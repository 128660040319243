#cart-modal-ctn {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 10px 50px;
}

#img-cart-modal {
    width: 100px;
    height: 153px;
}

.cart-modal-child {
    margin-left: 30px;
}

#cart-modal-price {
    margin-top: 20px;
}

#cart-modal-btn-ctn {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

#btn-cart-modal {
    width: 50%;
    height: 50px;
    background: #000 none;
    border-color: #000;
    color: #fff;
    font-weight: 400;
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s;
}

#btn-cart-modal:hover {
    border: none;
    background-color: rgb(102, 102, 102);
}
