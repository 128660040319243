#checkout-outer-ctn {
    width: 100%;
    background-color: rgb(244, 244, 244);
    height: 100vh;
}

.checkout-page-card-ctn {
    background-color: white;
    margin: 20px auto;
    padding: 20px;
    width: 50%;
}
.checkout-left {
    width: 100%;
}

.checkout-right {
    height: fit-content;
}

.checkout-right-inner {
    width: 100%;
}

#checkout-address-select-ctn {
    width: 100%;
    margin: 0;
    /* padding: 0; */
}

#checkout-inner-ctn {
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


#checkout-inner-left-bottom {
    margin-top: 20px;
}

#checkout-items-img-preview-ctn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#checkout-items-img-preview-ctn img {
    width: 50px;
    height: 77px;
}


.checkout-inner-child {
    width: 55%;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    /* border: red solid 2px; */
}

.order-summary-title {
    font-weight: 700;
    margin: 10px 0;
}

.order-summary-subtitle {
    margin-left: 10px;
}

#checkout-address-selections-ctn {
    font-weight: 700;
    font-size: small;
    margin: 10px;
}

.order-summary-sub-lines {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#checkout-place-order-btn-ctn {
    margin-top: 20px;
}

#checkout-place-order-btn {
    width: 100%;
    height: 50px;
    background: #000 none;
    border-color: #000;
    color: #fff;
    font-weight: 400;
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s;
}

#checkout-place-order-btn:hover {
    border: none;
    background-color: rgb(102, 102, 102);
}

#checkout-place-order-btn-disabled {
    width: 100%;
    height: 50px;
    background-color: rgb(102, 102, 102);
    color: #fff;
}
