#single-search-result-ctn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
}

#search-result-preview-img {
    width: 50px;
    height: 70px;
}

#search-result-name {
    margin-left: 20px;
}
