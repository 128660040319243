#modal-review-form-ctn {
    margin-left: 30px;
}

#upload-image-ctn {
    width: 150px;
    height: 150px;
}

#preview-review-image {
    width: 150px;
    height: 150px;
}

#create-review-btn {
    border: 1px black solid;
    padding: 0;
    background: transparent;
    cursor: pointer;
    min-width: 220px;
    min-height: 40px;
    transition: background-color 0.5s;
}

#create-review-btn:hover {
    border: none;
    background-color: rgb(230, 230, 230);
}



#create-review-modal-content-ctn {
    display: flex;
    flex-direction: row;
    margin: 30px 30px 60px 30px;
}

.review-form {
    width: 500px;
}

.review-form label {
    font-weight: 700;
}

.review-form input {
    width: 500px;
    height: 40px;
    font-size: 15px;
}

.review-form textarea {
    width: 500px;
    font-size: 15px;
    resize: none;
    height: 100px;
}

.file-button {
    border: 1px black solid;
    padding: 0;
    background: transparent;
    cursor: pointer;
    min-width: 220px;
    min-height: 40px;
    transition: background-color 0.5s;
    margin-top: 20px;
}

.file-button:hover {
    border: none;
    background-color: rgb(230, 230, 230);
}


input[type="file"] {
    display: none;
}

#preview-review-image {
    object-fit: cover;
    object-position: center
}

#upload-image-ctn {
    /* border: red solid 1px; */
    margin: 20px 0;
}

.submit-review-btn {
    border: 1px black solid;
    padding: 0;
    color: white;
    background: black;
    cursor: pointer;
    min-width: 220px;
    min-height: 40px;
    transition: background-color 0.5s;
    margin-top: 20px;
}

textarea::placeholder {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

textarea {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.submit-review-btn:hover {
    border: none;
    background-color: rgb(102, 102, 102);
}

#cancel-review-btn {
    border-style: solid;
    border-bottom-width: 1px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 0;
    background: transparent;
    cursor: pointer;
    /* font-size: 10px; */
    transition: border-bottom-width 0.5s;
    margin-left: 30px;
}

#cancel-review-btn:hover {
    border-bottom-width: 2px;
}
