.product-detail-outer-ctn {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-top: 20px;
    max-width: 1660px;
}

#product-detatil-top-ctn {
    /* border: 1px solid red; */
    margin: 10px 0;
    display: flex;
    flex-direction: row;
}

#product-detail-top-child-left {
    /* max-width: 1650px; */
    width: 65%;
    /* border: 10px solid blue; */
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap; */
}

#product-detail-image-ctn {
    /* border: 12px dotted yellowgreen; */
    /* display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    column-gap: 4px;
    row-gap: 4px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

#product-single-image-ctn {
    /* padding-bottom: 153.37%; */
    /* width: 511px;
    height: 784px; */
    object-fit: cover;
    /* border: 5px solid; */
    width: 45%;
    margin: 4px;
}

#product-single-image-ctn img {
    /* display:block;
    margin: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    pointer-events: auto;
    transform: translate3d(0px, 0px, 0px) scale(1); */
    max-width: 100%;
    max-height: 100%;
}

#product-detail-top-child-right {
    width: 30%;
    padding-left: 10px;
}



#product-detail-rating-ctn {
    margin-top: 20px;
    /* font-weight: 700; */
}


#product-detail-name-ctn {
    font-weight: 400;
    margin-top: 20px;
}

#product-detail-name-ctn p {
    font-weight: 600;
    font-size: larger;
}

#product-detail-brand-ctn {
    font-weight: 200;
}

#product-detail-price-ctn {
    font-weight: 700;
    margin-top: 20px;
}

#product-detail-about-ctn {
    margin-top: 20px;
    width: 90%;
}


#product-detail-num-select-ctn {
    margin: 20px 0;
}



.quantity-selected-box {
    font-size: large;
    border: rgb(57, 57, 57) 1px solid;
    width: 50px;
    height: 50px;
    cursor: pointer;
}


/* #product-detail-bottom-ctn {
    border: 1px solid black;
} */

#btn-add-to-cart {
    width: 100%;
    height: 50px;
    background: #000 none;
    border-color: #000;
    color: #fff;
    font-weight: 400;
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s;
}


#btn-add-to-cart:hover {
    border: none;
    background-color: rgb(102, 102, 102);
}

#btn-add-to-bag-bag-icon {
    font-size: 15px;
    margin-bottom: 8px;
    margin-right: 8px;
}

#btn-add-to-cart div {
    font-size: small;
    margin: 0 3px;
}

#detail-gift-ctn {
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-between;
}
