#cart-item-detail-ctn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#cart-item-img-detail {
    display: flex;
    flex-direction: row;
    width: 60%;

}

#cart-item-preview-img {
    max-width: 100px;
    max-height: 153px;
}

#cart-item-title-quantity {
    display: flex;
    flex-direction: column;
    margin-left: 120px;
}

#cart-item-quantity-select {
    border: none;
    cursor: pointer;
    color: rgb(20, 98, 169);
}

#cart-item-product-price {
    font-weight: 700;
}
