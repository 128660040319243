#nav-user-ctn {
    position: relative;
    margin-right: 30px;
}
#nav-user-button {
    border: none;
    background-color: white;
    font-weight: 500;
    font-size: large;
    cursor: pointer;
    margin-top: 10px;
}
