.single-product-card-ctn {
    margin: auto;
    width: 100%;
}

#single-product-card-img-ctn {
    margin-bottom: 10px;
}

#single-product-card-img-ctn img {
    max-width: 100%;
    max-height: 100%;
}

.product-card {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: black;
    /* border: black solid 2px; */
    width: fit-content;
}

#single-card-product-name {
    border-bottom: none;
    transition: border-bottom-width 0.5s;
    color: rgb(57, 57, 57);
    font-weight: 400;
    font-size: 15px;
    max-width: 100%;

}

#single-card-product-name:hover {
    border-bottom:black, solid, 1px;
}

#single-card-product-price {
    font-weight: 700;
    font-size: 10px;
}

#single-card-product-rating span {
    font-weight: 400;
    font-size: 12px;
    color: rgb(57, 57, 57);
    margin-left: 10px;
}

#single-card-product-delivery span {
    font-weight: 400;
    font-size: 12px;
    color: rgb(57, 57, 57);
}
