* {
    margin:0;
    padding:0;
}
.nav-bar-outer {
    display: flex;
    width: 95%;
    /* background-color: yellow; */
    margin:auto;
    flex-direction: row;
    justify-content: space-between;
}

.nav-bar {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 30%;
}

#nav-upper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}



#nav-bar-child-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#logo-img {
    width: 148px;
    height: 100%;
}

.nav-bar-right-ctn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 238px;
}

.nav-bar-headers {
    text-decoration: none;
    color: black;
}

.fa-bag-shopping {
    font-size: 24px;
    margin-top: 5px;
    color: rgb(57, 57, 57);
}

.fa-bag-shopping:hover {
    color: gray;
}


.nav-bar-cart-titems-number {
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
}
