#single-review-outer {
    border-bottom: 1px rgb(227, 227, 227) solid;
}

#single-review-ctn {
    /* border: blue solid 1px; */
    display: flex;
    flex-direction: row;
    margin: 20px auto;
}

#single-review-left {
    width: 220px;
}


#single-review-right-outer {
    width: 100%;
}

#single-review-right {
    width: 34%;
    margin-left: 80px;
}

#review-headline-ctn {
    margin: 10px 0;
}

#review-content-ctn {
    /* font-family: Arial, Helvetica, sans-serif; */
    font-size: small;
    max-width: 400px;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
}

#review-img-ctn {
    margin: 20px 0;
}

#review-img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    object-position: center
}
