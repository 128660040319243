.shop-now-btn {
    border: 1px black solid;
    padding: 0;
    background: transparent;
    cursor: pointer;
    min-width: 220px;
    min-height: 40px;
    transition: background-color 0.5s;
    margin-top: 20px;
}

.shop-now-btn:hover {
    border: none;
    background-color: rgb(230, 230, 230);
}

#shop-now-text {
    text-decoration: none;
    color: black;
}
