#nav-account-ctn {
    position: relative;
    margin-right: 30px;
}

#nav-account-button {
    border: none;
    background-color: white;
    font-weight: 500;
    font-size: large;
    margin-top: 10px;
    cursor: pointer;
}

#user-icon {
    color: rgb(57, 57, 57);
}

#user-icon:hover {
    color: gray;
}
