.auth-page-outer-ctn {
    width: 90%;
    margin:  30px auto;
}

.auth-page-form-ctn {
    /* width:50%; */
    max-width: 500px;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    padding: 30px;
}
