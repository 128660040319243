#empty-bag-page-outer {
    /* background-color: white; */
    margin: 20px auto;
    padding: 20px;
    width: 30%;
}

#empty-bag-page-outer div {
    font-size: x-large;
}

#back-to-shopping {
    text-decoration: none;
}

