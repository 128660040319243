#edit-primary-ctn {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
#edit-primary-address-checkbox {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    cursor: pointer;
}

.address-edit-delete-btn {
    border-style: solid;
    border-bottom-width: 1px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 0;
    background: transparent;
    cursor: pointer;
    font-size: 10px;
    transition: border-bottom-width 0.5s;
}

.address-edit-delete-btn:hover {
    border-bottom-width: 2px;
}
