.empty-user-bar-ctn {
    width: 100%;
    margin: auto;
    background-color: #F8F4EA
}

.all-products-outer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.all-products-container {
    width: 95%;
}
