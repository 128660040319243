#footer-outer-ctn {
    width: 100%;
    
    background-color: rgb(249, 249, 249);
    margin-top: 100px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}

#footer-inner-ctn {
    width: 50%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}

#footer-inner-ctn >* {
    margin: 10px 0 ;
}

#contact-icon-ctn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#contact-icon-ctn i {
    margin: 0 10px;
    font-size: 30px;
    color: rgb(57, 57, 57);
    transition: color 0.5s;
}

#contact-icon-ctn i:hover {
    color: gray;
}
