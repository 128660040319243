#btn-back-to-shopping {
    width: 100%;
    height: 50px;
    background: white none;
    color: rgb(57, 57, 57);
    font-weight: 400;
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color color 0.3s;
    margin-top: 20px;
    border: 1px solid rgb(102, 102,102);
}

#btn-back-to-shopping:hover {
    border: none;
    background-color: rgb(102, 102, 102);
    color: white;
}
