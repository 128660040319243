#my-account-container {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: row;
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
    border-top: 1px rgb(227, 227, 227) solid;
}

#sidebar-ctn {
    width: 300px;
    height: 100vh;
    /* border: black solid 1px; */
    padding-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

}

#side-bar-ul {
    padding-left: 0;
}

#sidebar-header {
    margin: auto;
}

#sidebar-li {
    list-style: none;
}

.sidebar-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s;
}

.sidebar-row:hover {
    background-color: rgb(227, 227, 227);
}

/* set color after row was selected */
.selected-sidebar-row {
    background-color: rgb(57,57,57);
    display: flex;
    flex-direction: row;
    align-items: center;
    /* cursor: pointer; */
}

.selected-sidebar-row .sidebar-row-child {
    color: white;
}
/* ---------------------------------- */


.sidebar-row-child {
    margin: 8px;
}

.sidebar-row-child i {
    font-size: 24px;
}

.sidebar-row-title {
    font-size: 18px;
}

.sidebar-row-about {
    font-size: 12px;
}

#content-ctn {
    /* background-color: rgb(244,244,244); */
    width: 100%;
}

#account-main-content {
    padding: 20px;
    margin: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
