#review-header-outer {
    border-top: 1px rgb(227, 227, 227) solid;
    border-bottom: 1px rgb(227, 227, 227) solid;
}

#review-title-ctn {
    margin: 20px 0;
}

#review-header-ctn {
    /* border: 3px red solid; */
    /* margin: 30px 0; */
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    /* justify-content: flex-start;
    max-width: 800px; */
}

#create-review-btn-ctn {
    margin-left: 50px;
}
