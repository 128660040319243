#cart-items-total-outer-ctn {
    width: 50%;
}

.subtotal-tax-ctn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#estimated-total-ctn {
    border-top: 1px rgb(227, 227, 227) solid;
    padding-top: 10px;
}


#btn-checkout-ctn {
    padding-top: 20px;
}


#btn-checkout {
    width: 100%;
    height: 50px;
    background: #000 none;
    border-color: #000;
    color: #fff;
    font-weight: 400;
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s;
}

#btn-checkout:hover {
    border: none;
    background-color: rgb(102, 102, 102);
}

#test {
    color: #fff;
    text-decoration: none;
}
