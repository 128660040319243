#address-cards-ctn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

#single-address-card {
    width: 40%;
    border: rgb(227, 227, 227) solid 1px;
    padding: 20px;
    margin-top: 20px;
    font-size: 13px;
}

.address-edit-delete-btn {
    border-style: solid;
    border-bottom-width: 1px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 0;
    background: transparent;
    cursor: pointer;
    font-size: 10px;
    transition: border-bottom-width 0.5s;
}

.address-edit-delete-btn:hover {
    border-bottom-width: 2px;
}
