.drop-down-ctn {
    display: flex;
    flex-direction: column;
    background-color: blue;
}

.drop-down-log-in {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    left: -25px;
    top: 30px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.child-drop-down {
    margin: 5px auto;
}

.child-drop-down:hover {
    text-decoration: underline;
    font-weight: 700;
}
