#search-bar-outer {
    position: relative;
}

#keyword-search-form {
    width: 100%;
}

#search-bar-ctn {
    display: flex;
    flex-direction: row;
    border: 1px solid #838d91;
    align-items: center;
    position: relative;
    width: clamp(268px, 45vw, 760px)
}

#search-bar-hidden-input {
    display: none;
}

#keyword-input {
    width: 100%;
    border: none;
    position: relative;
    height: 40px;
    text-indent: 5px;
    line-height: 2.4rem;
    font-size: medium;
    font-weight: 400;
}

#search-bar-ctn input:focus {
    outline: none;
}

#search-icon-ctn {
    margin-right: 8px;
    padding: 0 0 0 10px;
}

#search-bar-hidden-btn {
    background: transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
    overflow: visible;
    text-decoration: none;
    z-index: 2;
    padding: 8px;
    top: 2px;
}

#all-search-results-outer {
    width: clamp(268px, 45vw, 760px);
    border: 1px solid #e3e3e3;
    box-shadow: 0 0 5px rgba(0,0,0,0.11);
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 99;
    background-color: #fff;
}

#single-search-result-outer {
    border-top: 1px solid #e3e3e3;
    transition: background-color 0.5s;
}

#single-search-result-outer:hover {
    background-color: rgb(227, 227, 227);
}

#search-result-navlink {
    text-decoration: none;
    color: inherit;
}
